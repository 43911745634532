<template>
  <section class="gray pt-4">
    <div class="container" v-if="! is_loading">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="filter_search_opt">
            <a href="#" @click.prevent="openFilterSearch"
              >Filter Properties<i class="ml-2 ti-menu"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- property Sidebar -->
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div
            class="simple-sidebar sm-sidebar"
            id="filter_search"
            style="left: 0"
          >
            <div class="search-sidebar_header">
              <h4 class="ssh_heading">Close Filter</h4>
              <button
                @click.prevent="closeFilterSearch"
                class="w3-bar-item w3-button w3-large"
              >
                <i class="lni lni-close"></i>
              </button>
            </div>

            <!-- Find New Property -->
            <div class="sidebar-widgets">
              <div class="search-inner p-0">
                <h3>Filter</h3>
                <div class="d-navigation mb-4">
                  <ul>
                    <li :class="$route.query.show == 'active' ? 'active' : ''">
                      <router-link :to="{ query: { show: 'active' }}" class="d-block"
                        ><i class="ti-thumb-up"></i>Active <span class="float-end">{{ stats.active }}</span></router-link>
                    </li>
                    <li :class="$route.query.show == 'pending' ? 'active' : ''">
                      <router-link :to="{ query: { show: 'pending' }}" class="d-block"
                        ><i class="lni lni-hourglass"></i>Pending <span class="float-end">{{ stats.pending }}</span></router-link>
                    </li>
                    <li :class="$route.query.show == 'denied' ? 'active' : ''">
                      <router-link :to="{ query: { show: 'denied' }}" class="d-block"
                        ><i class="ti-close"></i>Denied <span class="float-end">{{ stats.denied }}</span></router-link>
                    </li>
                    <li :class="$route.query.show == 'closed' ? 'active' : ''">
                      <router-link :to="{ query: { show: 'closed' }}" class="d-block"
                        ><i class="lni lni-checkmark"></i>Closed <span class="float-end">{{ stats.closed }}</span></router-link>
                    </li>
                    <li :class="$route.query.show == 'all' ? 'active' : ''">
                      <router-link :to="{ query: { show: 'all' }}" class="d-block"
                        ><i class="ti-list"></i>All Time <span class="float-end">{{ stats.all }}</span></router-link>
                    </li>
                  </ul>
                </div>

                <form @submit.prevent="fetch">
                  <div class="form-group">
                    <label>Date From</label>
                    <datepicker input-class="form-control" placeholder="Date From" v-model="filter.date_from" />
                  </div>
                  <div class="form-group">
                    <label>Date To</label>
                    <datepicker input-class="form-control" placeholder="Date To" v-model="filter.date_to" />
                  </div>
                  <div class="form-group filter_button">
                    <button
                      type="submit"
                      class="btn btn btn-theme-light-2 rounded full-width"
                    >
                      <i class="lni lni-search mr-2"></i> Apply Filter
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Sidebar End -->
        </div>

        <div class="col-lg-8 col-md-12 list-layout">
          <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
              <div class="item-shorting-box">
                <div class="item-shorting clearfix">
                  <div class="left-column pull-left">
                    <h4 class="m-0">
                      Showing {{ pagination.total > 0 ? ((pagination.page-1)*10) + 1 : 0 }}-{{
                        (pagination.page - 1) * 10 + 10 >= pagination.total
                          ? pagination.total
                          : (pagination.page - 1) * 10 + 10
                      }}
                      of {{ pagination.total }} Results
                    </h4>
                  </div>
                </div>
                <div class="item-shorting-box-right" v-if="pagination.total > 1">
                  <div class="shorting-by text-right">
                    <select id="shorty" class="form-control" v-model="filter.sort" @change="fetch">
                      <option value="0" selected>Sort: Default</option>
                      <option value="1">Low Price</option>
                      <option value="2">High Price</option>
                      <option value="3">Most Popular</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Single Property Start -->
            <div class="dashboard-wraper" v-if="properties.length == 0">
              <h6>You have not posted any properties.</h6>
              <router-link :to="{ name: 'submit-property' }" class="btn btn-theme mt-3"><i class="lni lni-home me-2"></i>Submit New Property</router-link>
            </div>
            <div
              class="col-lg-12 col-md-12"
              v-for="(property, i) in properties"
              :key="`property-${i}`"
            >
              <div class="property-listing property-1">
                <div class="listing-img-wrapper">
                  <router-link
                    :to="{
                      name: 'my-listings.show',
                      params: { slug: property.slug },
                    }"
                  >
                    <span v-if="property.featured" class="is-featured">Promoted</span>
                    <img
                      :src="property.image"
                      class="img-fluid mx-auto"
                      :alt="property.name"
                    />
                  </router-link>
                </div>

                <div class="listing-content">
                  <div class="listing-detail-wrapper-box">
                    <div class="listing-detail-wrapper">
                      <div class="listing-short-detail">
                        <span :class="`badge float-end badge-${property.status == 'active' ? 'success' : property.status == 'denied' ? 'danger' : property.status == 'unpublished' ? 'info' : 'warning'}`">{{ property.status }}</span>
                        <h4 class="listing-name">
                          <router-link
                            :to="{
                              name: 'my-listings.show',
                              params: { slug: property.slug },
                            }"
                            >{{ property.name }}</router-link
                          >
                        </h4>
                        <span class="prt-types sale mt-0"
                          >For {{ property.type }}</span
                        >
                        <span class="mb-0 text-muted text-tiny float-end">{{ property.date }}</span>
                      </div>
                    </div>
                    <div class="listing-detail-wrapper py-0">
                      <div class="list-price">
                        <h6 class="listing-card-info-price mb-0">
                          <small>KES</small> {{ property.amount }}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div class="price-features-wrapper">
                    <div class="list-fx-features my-0">
                      <div
                        class="listing-card-info-icon"
                        v-for="(quality, j) in property.qualities"
                        :key="`quality-${(i + 1) * (j + 1)}`"
                      >
                        <div class="inc-fleat-icon">
                          <img :src="quality.icon" width="13" alt="" />
                        </div>
                        {{ quality.name }}
                      </div>
                    </div>
                  </div>

                  <div class="listing-footer-wrapper">
                    <div class="listing-locate">
                      <span class="listing-location"
                        ><i class="ti-location-pin"></i
                        >{{ property.region }}</span
                      >
                    </div>
                    <div class="listing-detail-btn">
                      <router-link
                        :to="{
                          name: 'my-listings.show',
                          params: { slug: property.slug },
                        }"
                        class="more-btn"
                        >View</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Single Property End -->
          </div>

          <!-- Pagination -->
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <pagination
                v-model="pagination.page"
                :records="pagination.total"
                :perPage="pagination.per_page"
                @paginate="fetch"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="is_loading" />
  </section>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker
  },

  mounted() {
    if(!this.$route.query.show) {
      this.$router.push({ query: { show: 'active' }})
    }

    this.fetch();
    this.getStats()
  },

  data() {
    return {
      properties: [],
      pagination: {},
      filter: {
        date_from: null,
        date_to: null,
        sort: 0
      },
      is_loading: true,
      stats: {}
    };
  },

  methods: {
    openFilterSearch() {
      document.getElementById("filter_search").style.display = "block";
    },

    closeFilterSearch() {
      document.getElementById("filter_search").style.display = "none";
    },

    getStats() {
      this.$axios.get('/api/v1/my-listings/stats').then(response => {
        this.stats = response.data.stats
      })
    },

    fetch() {
      this.is_loading = true
      let url = `/api/v1/my-listings?sort=${this.filter.sort}`

      if(this.pagination.page) {
        url += `&page=${this.pagination.page}`
      }

      if(this.filter.date_from) {
        url += `&date_from=${new Date(this.filter.date_from).toISOString().slice(0, 10)}`
      }

      if(this.filter.date_to) {
        url += `&date_to=${new Date(this.filter.date_to).toISOString().slice(0, 10)}`
      }

      this.$axios.get(url).then((response) => {
        this.properties = response.data.properties;
        this.pagination = response.data.pagination;
        this.is_loading = false
      });
    }
  },

  computed: {
    full_path() {
      return this.$route.fullPath
    }
  },

  watch: {
    full_path() {
      this.fetch()
    }
  }
};
</script>
